<template>
  <div class="container">
    <van-tabs
      v-model="topSelect"
      class="top-active-tab"
      color="#87C6FF"
      title-active-color="#87C6FF"
      line-width="1.2rem"
    >
      <van-tab
        v-for="item in topList"
        :key="item.value"
        :title="item.label"
        :name="item.value"
      ></van-tab>
    </van-tabs>

    <van-collapse v-model="activeSecond">
      <van-collapse-item
        v-for="item in secondList"
        :key="item.value"
        :name="item.value"
        :title="item.label"
      >
        <div v-for="contentItem in contentList(item)" 
              :key="contentItem.value"
              class="content-item"
        >
          <div class="content-title">{{contentItem.label}}</div>
          <div v-html="contentItem.content"></div>
        </div>
      </van-collapse-item>
    </van-collapse>
    <!-- <van-tabs
      v-model="secondSelect"
      class="second-active-tab"
      line-height="0"
      color="#87C6FF"
      title-active-color="#87C6FF"
    >
      <van-tab
        v-for="item in secondList"
        :key="item.value"
        :title="item.label"
        :name="item.value"
      >
        <div class="mt-20">
          <van-collapse v-model="activeNames">
            <van-collapse-item
              v-for="item in contentList"
              :key="item.value"
              :name="item.value"
              :title="item.label"
            >
              <div v-html="item.content"></div>
            </van-collapse-item>
          </van-collapse>
        </div>
      </van-tab>
    </van-tabs> -->
  </div>
</template>

<script>
import { getList } from '@/api/help';

export default {
  name: 'HelpPage',

  data: function () {
    return {
      info: [],

      topSelect: 0,
      secondSelect: 0,
      activeNames: [],
      activeSecond: []
    };
  },

  computed: {
    topList() {
      const list = this.info;
      return list.map((x) => ({
        value: x.id,
        label: x.name
      }));
    },
    secondList() {
      const item = this.info.find((x) => x.id === this.topSelect);
      if (
        Object.prototype.toString.call(item) === '[object Object]' &&
        'children' in item
      ) {
        return item.children.map((x) => ({
          value: x.id,
          label: x.name
        }));
      }
      return [];
    },
    // contentList() {
    //   const item = this.info.find((x) => x.id === this.topSelect);
    //   if (
    //     Object.prototype.toString.call(item) === '[object Object]' &&
    //     'children' in item
    //   ) {
    //     const secondItem = item.children.find(
    //       (x) => x.id === this.secondSelect
    //     );
    //     if (
    //       Object.prototype.toString.call(secondItem) === '[object Object]' &&
    //       'list' in secondItem
    //     ) {
    //       return secondItem.list.map((x) => ({
    //         value: x.id,
    //         label: x.name,
    //         content: x.content
    //       }));
    //     }
    //   }
    //   return [];
    // }
  },

  created() {
    this.loadData();
  },

  methods: {
    async loadData() {
      const { data } = await getList();
      this.info = data;
      this.topSelect = data[0].id;
      this.secondSelect = data[0].children[0].id;
    },
    contentList(obj) {
      const item = this.info.find((x) => x.id === this.topSelect);
      if (
        Object.prototype.toString.call(item) === '[object Object]' &&
        'children' in item
      ) {
        const secondItem = item.children.find(
          (x) => x.id === obj.value
        );
        if (
          Object.prototype.toString.call(secondItem) === '[object Object]' &&
          'list' in secondItem
        ) {
          return secondItem.list.map((x) => ({
            value: x.id,
            label: x.name,
            content: x.content
          }));
        }
      }
      return [];      
    },
  }
};
</script>
<style lang="scss">
.top-active-tab {
  height: 1.2rem;
  .van-tabs__wrap {
    height: 1.2rem;
  }
  .van-tab .van-tab__text {
    font-size: 0.28rem;
    color: #a3abbf;
  }
  .van-tab--active .van-tab__text {
    font-size: 0.4rem;
    color: #87c6ff;
    font-weight: bold;
  }
}
.second-active-tab {
  height: 0.8rem;
  font-size: 0.28rem;
  .van-tabs__wrap {
    height: 0.8rem;
  }
  .van-tab .van-tab__text {
    color: #a3abbf;
  }
  .van-tab--active .van-tab__text {
    color: #87c6ff;
  }
}
</style>

<style lang="scss" scoped>
.container {
  width: 100vw;
  background-color: #fff;
}
.title {
  font-size: 0.28rem;
  color: #0a1738;
  line-height: 0.4rem;
  letter-spacing: 0.02rem;
}
.content-item{
  margin-top: .12rem;
  position:relative;

  .content-title {
    color: #3f3f3f;
    font-size: .3rem;
    font-weight: bold;
  }
}
.content-item + .content-item {
 &:after  {
  content: '';
  position:absolute;
  top: -0.06rem;
  left: 50%;
  transform: translateX(-50%);
  width: 96%;
  border-top: .01rem solid #c4c4c4;

 }
}
</style>

